"use client";
import { useState } from "react";
import { randomBytes } from "crypto";
import { UniversalInput } from "@/src/components/universalInput";
import { Button } from "@/src/components/buttons";
import { openToast } from "@/src/utils/toasts";
import apiFetch from "@/src/utils/apiFetch";
import { sendSimpleNotification } from "../notifications/sendSimpleNotification";
import { getItemId } from "@/backend/utils";
import { getItemsIds } from "@/src/utils/utils";

export function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [code, setCode] = useState("");

  return (
    <div className="fade c1 gap15">
      {!isCodeSent && (
        <EmailInput
          email={email}
          setEmail={setEmail}
          onCodeSent={(generatedCode) => {
            setCode(generatedCode);
            setIsCodeSent(true);
          }}
        />
      )}

      {isCodeSent && !isCodeCorrect && (
        <CodeVerification
          code={code}
          onCodeCorrect={() => setIsCodeCorrect(true)}
        />
      )}

      {isCodeSent && isCodeCorrect && <NewPasswordInput email={email} />}
    </div>
  );
}

export function EmailInput({
  email,
  setEmail,
  onCodeSent,
}: {
  email: string;
  setEmail: (email: string) => void;
  onCodeSent: (code: string) => void;
}) {
  const handleSendEmail = async () => {
    const generatedCode = randomBytes(3).toString("hex").toUpperCase();
    const result = await apiFetch("users", "loadByEmail", {
      email: email,
    });
    if (!result)
      throw new Error("Aucun utilisateur trouvé avec cette adresse email");

    await apiFetch("notifications", "sendEmailToUsers", {
      body: resetPasswordEmailBody(generatedCode),
      email: email,
      title: "Reset your password",
    });

    onCodeSent(generatedCode);
  };

  return (
    <div className="c1 gap15">
      <h1>Mot de passe oublié ?</h1>
      <div className="gray">Veuillez renseigner votre adresse mail</div>
      <UniversalInput
        name="Email"
        type="text"
        value={email}
        onChange={(newValue) => setEmail(newValue)}
      />
      <Button onClick={handleSendEmail}>
        Envoyer le code
      </Button>
    </div>
  );
}

const resetPasswordEmailBody = (generatedCode: string) => `
  Bonjour,
  Vous avez demandé la réinitialisation de votre mot de passe. Veuillez utiliser le code de récupération ci-dessous pour procéder à la réinitialisation :
  Code de récupération :  ${generatedCode}
  Cordialement,
  Équipe ECGBox
  www.ecgbox.com
`;

export function CodeVerification({
  code,
  onCodeCorrect,
}: {
  code: string;
  onCodeCorrect: Function;
}) {
  const [userCode, setUserCode] = useState("");

  const handleVerifyCode = () => {
    if (userCode === code) {
      onCodeCorrect();
    } else {
      openToast("error", "Code incorrect");
    }
  };

  return (
    <div className="c1 gap10">
      <div>
        <h1>Entrez le code</h1>
        <div className="gray">Veuillez entrer le code reçu</div>
      </div>
      <UniversalInput
        name="Code de récuperation"
        type="text"
        value={userCode}
        onChange={(newValue) => setUserCode(newValue)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleVerifyCode();
          }
        }}
      />
      <Button  onClick={handleVerifyCode}>
        Valider
      </Button>
    </div>
  );
}

export function NewPasswordInput({ email }: { email: string }) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleResetPassword = async () => {
    if (password === passwordConfirmation) {
      await apiFetch("users", "resetPassword", {
        email: email,
        password: password,
      });
      window.open(`/login`, "_self");
    } else {
      openToast("error", "Les mots de passe ne correspondent pas");
    }
  };

  return (
    <div className="c1 gap10">
      <h1>Entrez le Nouveau mot de passe</h1>
      <div className="gray">Veuillez entrer un nouveau mot de passe</div>
      <UniversalInput
        name="Mot de passe"
        type="password"
        value={password}
        onChange={(newValue) => setPassword(newValue)}
      />
      <UniversalInput
        name="Confirmation du Mot de passe"
        type="password"
        value={passwordConfirmation}
        onChange={(newValue) => setPasswordConfirmation(newValue)}
      />
      <Button  onClick={handleResetPassword}>
        Valider
      </Button>
    </div>
  );
}
