import apiFetch from "@/src/utils/apiFetch";

import { Button } from "@/src/components/buttons";
import { Input } from "@/src/components/universalInput";
import { User, frequentRoles } from "@/src/data/allTypes";
import {
  CARDIOLOGY_SPECIALIITY_ID,
  GENERAL_SPECIALITY_ID,
} from "@/src/data/globalVariables";
import {
  getItemId,
  getRemedType,
  getUserId,
  jsonAbstract,
  openToast,
} from "@/src/utils/utils";
import moment from "moment";
import { useState } from "react";
import { ResetPassword } from "./resetPassword";

function LoginPage({ onLogin }: { onLogin: (user: User) => void }) {
  return (
    <div id="loginPage" className="c2 mc1 p20 gap30 acenter page">
      <LoginImage></LoginImage>
      <div className="p10">
        <LoginForm onLogin={onLogin}></LoginForm>
      </div>
    </div>
  );
}

function LoginImage() {
  return (
    <div
      id="loginHeader"
      className="gray-bg br c1 center"
      style={{ height: "100%", position: "relative", overflow: "hidden" }}
    >
      <img
        src="./images/ecg-background2.jpeg"
        alt=""
        style={{
          filter: "brightness(0.5)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
      />

      <div className="tcenter c1 gap10 center white">
        <img
          src="./images/new-logo5-white.png"
          alt=""
          style={{ height: "100px" }}
        />
        <div className="f22 bold" style={{ fontSize: "25px" }}>
          L&apos;expertise d&apos;un cardiologue en un <span>clic</span>
        </div>
        <div className="" style={{ fontSize: "14px", marginBottom: "40px" }}>
          Nos cardiologues interprêtent vos ECGs en ligne
        </div>
      </div>
    </div>
  );
}

function LoginFormLogo() {
  return (
    <div
      className="c1 center tleft mbottom20 mobileOnly"
      style={{ width: "100%" }}
    >
      <img src="./images/new-logo5-red.png" alt="" style={{ height: "60px" }} />
    </div>
  );
}

function LoginForm({ onLogin }: { onLogin: (user: User) => void }) {
  let [isSignup, setIsSignup] = useState(false);
  let [isCardiologist, setIsCardiologist] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  let [address, setAddress] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [lastName, setLastName] = useState("");
  let [firstName, setFirstName] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");

  async function onLoginPressed() {
    let user = await apiFetch("users", "login", {
      email,
      password,
      req: null as any,
      res: null as any,
    });

    if (!user) throw new Error("Erreur lors de la connexion");
    onLogin(user);
  }

  async function onSignupPressed() {
    try {
      // let existingUser = await loadUser({ email, password });

      let user = await apiFetch("users", "addOne", {
        user: {
          email,
          firstName,
          lastName,
          password,
          sex: "homme",
          grade: "Dr",
          address: "",
          permissions: [],
          lastOnlineDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          phoneNumber,
          profilePic: null,
          speciality: isCardiologist
            ? jsonAbstract(CARDIOLOGY_SPECIALIITY_ID)
            : jsonAbstract(GENERAL_SPECIALITY_ID),
          role: isCardiologist
            ? getItemId(frequentRoles.cardiologue)
            : getItemId(frequentRoles.private_doctor),
          service: null,
          interdictions: [],
          lastTokenDate: null,
          token: null,
          isValidated: false,
          creator: getUserId(),
        },
      });
      if (!user) throw new Error("Erreur lors de la création du compte");
      if (!user.id) throw new Error("Erreur lors de la création du compte");
      onLoginPressed();
    } catch (e: any) {
      openToast("error", e?.message || e?.toString());
    }
  }
  if (isResetPassword) return <ResetPassword />;
  return (
    <>
      {isSignup ? (
        <div
          key={`signupForm`}
          className="fade c1 gap15"
          style={{ height: "100%" }}
        >
          <LoginFormLogo></LoginFormLogo>
          <div>
            <h1>Créer un compte</h1>
            <div className="gray">Veuillez créer un compte pour continuer</div>
          </div>
          <Input
            name=""
            type="chips"
            value={isCardiologist ? "Cardiologue" : "Médecin"}
            options={[
              { value: "Médecin", label: "Médecin" },
              { value: "Cardiologue", label: "Cardiologue" },
            ]}
            onChange={(newValue) => {
              setIsCardiologist(newValue === "Cardiologue");
            }}
          ></Input>
          <Input
            name="Nom"
            type="text"
            value={lastName}
            onChange={(newValue) => {
              setLastName(newValue);
            }}
          ></Input>
          <Input
            name="Prénom"
            type="text"
            value={firstName}
            onChange={(newValue) => {
              setFirstName(newValue);
            }}
          ></Input>

          <Input
            name={"E-mail"}
            type="text"
            value={email}
            onKeyUp={async (e) => {
              if (e.key === "Enter") await onSignupPressed();
            }}
            onChange={(newValue) => {
              setEmail(newValue);
            }}
          ></Input>
          <Input
            name="Mot de passe"
            type="password"
            value={password}
            onKeyUp={async (e) => {
              if (e.key === "Enter") await onSignupPressed();
            }}
            onChange={(newValue) => {
              setPassword(newValue);
            }}
          ></Input>
          <Button
            onClick={async () => {
              await onSignupPressed();
            }}
          >
            Créer un compte
          </Button>
          <div className="gray tcenter mtop20">Vous avez déjà un compte ?</div>
          <Button
            type={"tertiary"}
            onClick={() => {
              setIsSignup(false);
            }}
          >
            Utiliser mon compte
          </Button>
        </div>
      ) : (
        <div key={`loginForm`} className="fade c1 gap15">
          <LoginFormLogo></LoginFormLogo>
          <div>
            <h1 className="main-color">Se connecter</h1>
            <div className="gray">Veuillez vous connecter pour continuer</div>
          </div>
          <Input
            name={getRemedType() == "ECG" ? "E-mail" : "Nom ou E-mail"}
            type="text"
            value={email}
            onKeyUp={async (e) => {
              if (e.key === "Enter") await onLoginPressed();
            }}
            onChange={(newValue) => {
              setEmail(newValue);
            }}
          ></Input>
          <div>
            {" "}
            <Input
              name="Mot de passe"
              type="password"
              value={password}
              onKeyUp={async (e) => {
                if (e.key === "Enter") await onLoginPressed();
              }}
              onChange={(newValue) => {
                setPassword(newValue);
              }}
            ></Input>
            <div
              className="pointer gray f10"
              onClick={() => setIsResetPassword(true)}
            >
              Vous avez oublié votre mot de passe ?
            </div>
          </div>
          <Button
            onClick={async () => {
              await onLoginPressed();
            }}
          >
            SE CONNECTER
          </Button>

          <div className="gray tcenter mtop20">
            Vous n&apos;avez pas encore de compte ?
          </div>
          <Button
            type={"tertiary"}
            onClick={() => {
              setIsSignup(true);
            }}
          >
            Créer un compte
          </Button>

          <div className="mbottom30"></div>
        </div>
      )}
    </>
  );
}

export { LoginPage };
