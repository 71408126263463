"use client";
import { flutterPost } from "@/src/utils/utils";
import PageRoot from "../pageRoot";
import { LoginPage } from "./loginPage";

function Page() {
  return (
    <PageRoot>
      <LoginPage
        onLogin={(user) => {
          console.log("user", user);
          flutterPost({ type: "request_notification_permission" });
          flutterPost({ type: "request_token" });
          localStorage.removeItem("serverData");
          window.location.href = "/";
        }}
      />
    </PageRoot>
  );
}

export default Page;
